.App {
  text-align: center;
  background-color: transparent;
  color: darkmagenta;
  font-family: 'Courier New', Courier, monospace;
  font-size: 20px;
  height: 100%;
  padding-left: 22px;
  margin-left: -3mm;
  margin-top: -1cm;
  display: block;
}
.App h1{
  font-size: 40px;
  font-family: 'Courier New', Courier, monospace;
  animation: heartbeat 0.66s infinite linear;
  -webkit-animation: heartbeat 0.66s infinite linear;
  transition: all 0.33s  linear;
  -webkit-transition: all 0.33s  linear;
  -moz-transition: all 0.33s  linear;
  -ms-transition: all 0.33s  linear;
  -o-transition: all 0.33s  linear;
}
@keyframes heartbeat {
  50%{
    transform: scale(1.2);  
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
  }
  100%{
    transform: scale(1);  
    -webkit-transform: scale(1 );
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }
}
.App p{
  text-decoration:  underline solid #3e1574;
  font-size: 30px;
  padding-bottom: 12px;
}
.App .melder{
  display: flex;
  flex-direction: row;
  margin-top: -3cm;
  margin-left: 80%;
}
.loger{
    width: 70px;
    height: 70px;
    background-color: transparent;
    z-index: 1;
    padding-right: 50px;
}
.loger img{
  width: 100%;
  height: 100%;
  box-shadow: 2px 2px 2px 0px #3e1574;
}
.news{
  width: 70px;
  height: 70px;
  background-color: transparent;
  z-index: 1;

}
.news img{
  width: 100%;
  height: 100%;
  box-shadow: 2px 2px 2px 0px #3e1574;
}
.show-login-button {
  cursor: pointer;
}
.logger{
  width: 300px;
  height: 70px;
  background-color: #159455;
  font-size: 30px;
}
.loginOptions {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: black;
}

.login-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 300px;
}

.close-button {
  margin-top: 10px;
  padding: 10px 20px;
  background: red;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn {
  margin: 10px 0;
  padding: 10px 20px;
  width: 100%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-primary {
  background-color: blue;
  color: white;
}

.link-button {
  background: none;
  color: blue;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  font-size: 20px;
}

.social-login {
  margin-top: 20px;
}

.social-login {
  background-color: transparent;
  color: white;
}
.social-login img{
  width: 30px;
  height: 30px;
  margin-left: -1cm;
  padding-right: 5px;
}

.google {
  background-color: #02df83;
}

.facebook {
  background-color: #3b5998;
}

.microsoft {
  background-color: #00a2ed;
}

form {
  display: flex;
  flex-direction: column;
}

input {
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
@media (max-width: 600px) {
  html body {
      overflow: hidden;
  }
  .scoreboard {
    font-size: 16px;
  }
  .game-container{
      background-image: url('gameObtacles/background.gif');
      background-repeat: no-repeat;
      background-size: contain;
      overflow-x: hidden;
      overflow-y: hidden;
      width: 100%;
      height: 100%;
  }

  .game-over {
    font-size: 18px;
    flex-direction: column;
  }
  .game-over button {
      width: 100px;
      height: 100px;
  }
  .full-screen1{
      width: 98%;
      height: 98%;
      overflow: hidden;
      justify-content: center;
      justify-items: center;
  }
  .firstAdd{
      height: 100px;
  }
  .secondAdd{
      margin-top: -15cm;
      z-index: 300;
      width: 70px;
      background-color: #ccc;
      height: 400px;
  }
  .App{
      font-size: 16px;
      overflow-y: scroll;
      overflow-x: hidden;
  }
  .App h1{
    font-size: 18px;
    width: 100%;
    height: 20px;
    margin-top: 4px;
  }
  .App p{
    font-size: 12px;
  }
  .loger{
    margin-top: 50px;
  }
  .loger img{
    width: 20px;
    height: 20px;
    margin-top: 40px;
  }
  .melder{
    margin-left: 85%;
  }
}