body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  padding: 14px;
  background-color: #000000;
  background-image: url('./space.png');
  background-size: cover;
  background-repeat: repeat-y;
  background-position: center;
  overflow: hidden;
}
html{
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media (max-width: 600px) {
  .game-container, .game-area {
    overflow: hidden;
  }
  body html{
    overflow: hidden;
  }
}
.ad-banner {
  height: 1px;
  width: 1px;
  overflow: hidden;
  position: absolute;
  left: -9999px;
}

.adblock-warning {
  background-color: yellow;
  color: red;
  padding: 10px;
  text-align: center;
}
