
.controll{
    width: 100%;
    height: 900px;
    background-color: rgba(53, 33, 126, 0.6);
    margin-left: -5mm;
    border-radius: 1%;
    -webkit-border-radius: 1%;
    -moz-border-radius: 1%;
    -ms-border-radius: 1%;
    -o-border-radius: 1%;
    border-color: beige;
    box-shadow: 2px 2px 2px 2px whitesmoke;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: self-start;
    flex-wrap: wrap;
    gap: 20px;
    padding: 30px;
}
.firstAdd{
    width: 400px;
    height: 400px;
}
.btn-primary{
    width: 200px;
    height: 100px;
    font-size: large;
    color: #FFFFFF;
    background-color: #333;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.facebook{background-color: #bbee33; width: 200px; height: 70px;
}
.btn1{
    background-color: cadetblue;
    font-size: 35px;
    text-align: center;
    box-shadow: 12px 12px #bbee33;
}
.btn1:hover{
    background-color: rgb(18, 165, 50);
    font-size: 45px;
    text-align: center;
    box-shadow: 2px 2px #bbee33;
}
.btn2{
    background-color: rgb(190, 154, 36);
    font-size: 35px;
    text-align: center;
    box-shadow: 12px 12px #bbee33;
}
.btn2:hover{
    background-color: rgb(153, 12, 12);
    font-size: 45px;
    text-align: center;
    box-shadow: 2px 2px #bbee33;
}
.btn3{
    background-color: rgb(141, 24, 69);
    font-size: 35px;
    text-align: center;
    box-shadow: 12px 12px #bbee33;
}
.btn3:hover{
    background-color: rgb(182, 124, 17);
    font-size: 45px;
    text-align: center;
    box-shadow: 2px 2px #bbee33;
}
.getti{
    display: flex;
    flex-direction: column;
    background-color: transparent;
    margin-left: 0%;
    width: 250px;
    height: 400px;
    justify-content: space-evenly;
    align-items: center;
    margin-left: 100px;
    padding-right: 6px;
}
.infos{
    width: 1000px;
    height: auto;
    background-color: transparent;
    z-index: 1;
}
.infos p{
    font-size: 16px;
    font-family: sans-serif;
    color: white;
}
strong{
    font-size: 20px;
    color: #bbee33;
}
.add3{
    width: 100%;
    background-color: #fff;
    height: 140px;
}
.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1); /* Changed to solid black */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 1000;
}
.full-screen label {
    font-size: 2em;
}
select {
  margin: 10px;
  padding: 5px;
}
.btn4{
    width: 200px;
    height: 70px;
    background-color: red;
    color: #FFFFFF;
}
.exitGame{
    width: 200px;
    height: 100px;
    z-index: 2000;
    margin-top: -25cm;
    margin-left: -90%;
    background-color: rgb(153, 12, 12);
    font-size: 40px;
}
.gamestart{
    justify-self: center;
    margin-top: -0.5cm;
}
.full-screen1{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 1);
    z-index: 1000;
    display: block;
    justify-content: top;
    align-items: center;
}
.full-screen1 button{
    width: 200px;
    height: 100px;
    background-color: brown;
    color: #FFFFFF;
    z-index: 3000;
    margin-top: -20cm;
    margin-left: -80%;
}
/* src/game.css */
/* game.css */
.game-container, .game-area {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  
  .game-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('gameObtacles/background.gif');
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .game-area {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .scoreboard {
    position: absolute;
    top: 10px;
    left: 10px;
    color: white;
    font-size: 24px;
  }
  
  .game-over {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
  }
  @media (max-width: 600px) {
    html body {
        overflow: hidden;
        justify-content: center;
        align-items: center;
        touch-action: none;
    }
    .scoreboard {
      font-size: 16px;
      z-index: 200000;
    }
    .controll{
        justify-content: center;
        justify-items: center;
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100%;
        background-color: #000000;
    }
    .game-container .game-area{
        background-image: url('gameObtacles/background.gif');
        background-repeat: no-repeat;
        background-size: cover;
        overflow-x: hidden;
        overflow-y: hidden;
        width: 100%;
        height: 100%;
        touch-action: none;
        position: absolute;
    }
  
    .game-over {
      font-size: 18px;
      flex-direction: column;
    }
    .game-over button {
        width: 50px;
        height: 50px;
    }
    .full-screen1 {
        overflow: hidden;
        height: 100%;
        width: 100%;
        position: fixed;
        justify-content: center;
        align-items: center;
      }
    .firstAdd{
        height: 100px;
    }
    .secondAdd{
        width: 100px;
        justify-content: center;
        align-items: center;
    }
    .infos{
        height: auto;
        padding-bottom: 3cm;
        flex-wrap: wrap;
        font-size: 12px;
        margin-left: -3%;
        width: 300px;
    }
    .infos p{
        font-size: 10px;
        flex-wrap: wrap;
        font-style: italic;
        height: auto;
        width: 300px;
    }
  }
  .blackout-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 10;
  }